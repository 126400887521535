$breakPoints: (
  s: 320px,
  m: 640px,
  l: 1024px,
  xl: 1400px,
);


:root {
  --steelblue-color: #36A9E1;
  --blue-color: #2A255C;
  --blue-light: #F0FAFE;
  --turquoise-color : #07ACB1;
  --red-color : #BD1823;
  --orange-color : #EF7B10;
  --purple-color : #A21A5C;
  --gray-bold : #383838;
  --gray-semibold : #7B7B7B;
  --gray-normal : #E1E1E1;
  --gray : #F5F5F5;

  --bg-color-btn : var(--steelblue-color);
  --bg-color-btn-alt: white;
  --color-btn: white;
  --radius-btn: 35px;
  --border-radius-box: 20px;
  --reserveChangeDesktop: 830px;
  --reserveHeaderHeight: 100px;
  --reserveFooterHeight: 200px;
  --width-menu-vertical: 300px;
  --max-width: 1170px;
  --box-shadow: -10px 30px 60px #00000014;
  
  // Variables de apilamineto z-index
  --z-back: -10;
  --z-normal: 1;
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;


  // Variables de fuente
  --title-font-size: 1.8rem;
  --subtitle-font-size: 1.3rem;
  --otherTitle-font-size: 1.2rem;
  --body-font-size: .875rem;
  --small-font-size: 0.825rem;

  @include from(m) {
    --title-font-size: 2.4rem;
    --subtitle-font-size: 1.5rem;
    --otherTitle-font-size: 1.42rem;
    --body-font-size: 1rem;
    --small-font-size: 0.825rem;
  }
  @include from(l) {
    --title-font-size: 3.2rem;
    --subtitle-font-size: 1.8rem;
    --otherTitle-font-size: 1.55rem;
    --small-font-size: 0.825rem;
  }
}

// PALETA DE COLORES VAAC
$steelblue-color: #36A9E1;
$blue-color: #2A255C;
$blue-light: #F0FAFE;
$turquoise-color : #07ACB1;
$red-color : #BD1823;
$orange-color : #EF7B10;
$purple-color : #A21A5C;

// GRISES ALTERNOS PARA EL DISEÑO WEB
$gray-bold : #383838;
$gray-semibold : #7B7B7B;
$gray-normal : #E1E1E1;
$gray : #F5F5F5;

$reserveChangeDesktop: 830px;
$reserveHeaderHeight: 80px;
$reserveFooterHeight: 200px;
