@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";


.main-profile-partner {
  --direction: column;
  min-width: 100%;
  display: flex;
  flex-direction: var(--direction);
  @include from(670px) {
    --direction: row;
  }
  @include from(830px) {
    --direction: column;
  }
  @include from(915px) {
    --direction: row;
  }
}


.main-profile-partner--parte-1 {
  // background-color: green;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 4;
  @include from(670px) {
    border-right: 1px solid var(--gray-normal);
  }
  @include from(830px) {
    border-right: none;
  }
  @include from(915px) {
    border-right: 1px solid var(--gray-normal);
  }
}

.main-profile-partner--img-container {
  width: 120px;
  height: 120px;
  display: block;
  border-radius: 50%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}


.main-profile-partner--parte-2 {
  flex: 6;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--gray-normal);
  @include from(670px) {
    padding-left: 2rem;
    border-top: none;
  }
  @include from(830px) {
    border-top: 1px solid var(--gray-normal);
    padding-left: 0;
  }
  @include from(915px) {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
    padding-left: 2rem;
  }

  div:not(:last-child) {
    border-bottom: 1px solid var(--gray-normal);
  }
  div:nth-child(1),  div:nth-child(2){
   padding-bottom: 15px;
  }
} 
