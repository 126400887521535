@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.main-report-problem {
  // background-color: turquoise;
  @include from(950px) {
    display: flex;
  }
}

.container-input-reserve-report {
  // width: 100%;
  // background-color: red;
  .ant-input {
    border-radius: 15px !important;
    border: 2px solid var(--turquoise-color) !important;
  }
}


.main-report-problem-parte-1 {
  @include from(950px) {
    padding-right: 1rem;
  }
  flex: 4;
}
.main-report-problem-parte-2 {
  padding-top: 1rem;
  border-top: 1px solid var(--gray-normal);
  flex: 6;
  @include from(950px) {
   padding-left: 2rem;
   padding-top: 0;
   border-top: none;
   border-left: 1px solid var(--gray-normal);
  }
}
