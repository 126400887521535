@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.body-step-4 {
  .data {
    margin-bottom: .3rem;
    line-height: 15px;
  }
}


.body-step-4-parte-1 {
  // background-color: yellow;
}


.body-step-4-parte-2 {
  // background-color: springgreen;
    border-top: 1px solid var(--gray-normal);
    border-bottom: 1px solid var(--gray-normal);
    margin-top: 1rem;
  @include from(990px) {
    display: flex;
    
   
    .data-1-step-4 {
     width: 35%;
    }
    .data-2-step-4 {
      // margin-left: 2rem;
      flex-grow: 1;
    }
  }
}
