@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.seat {
  position: relative;
  display: inline-block;
  height: 30px;
  width: 30px;
}
.seat-selected {
  background-image: url("../../../assets/bicycle-blue.svg") !important;
  background-size: contain;
  background-position: center;
}
.trx-selected {
  background-image: url("../../../assets/bicycle-blue.svg") !important; //colocar el trx blue aqui
  background-size: contain;
  background-position: center;
}
.seat-available {
  background-image: url("../../../assets/bicycle-turquose.svg") !important; 
  background-size: contain;
  background-position: center;
}
.trx-available {
  background-image: url("../../../assets/bicycle-turquose.svg") !important; //colocar el trx turquose aqui
  background-size: contain;
  background-position: center;
}
.seat-unavailable {
  background-image: url("../../../assets/bicycle-gray.svg") !important;
  background-size: contain;
  background-position: center;
  cursor: not-allowed;
}
.trx-unavailable {
  background-image: url("../../../assets/bicycle-gray.svg") !important;  //colocar el trx gray aqui
  background-size: contain;
  background-position: center;
  cursor: not-allowed;
}
.seat-admin-unavailable {
  background-image: url("../../../assets/bicycle-gray.svg") !important;
  background-size: contain;
  background-position: center;
}
.trx-admin-unavailable {
  background-image: url("../../../assets/bicycle-gray.svg") !important;  //colocar el trx gray aqui
  background-size: contain;
  background-position: center;
}
.seat-instructor {
  background-image: url("../../../assets/bicycle-steelblue.svg") !important;
  background-size: contain;
  background-position: center;
  cursor: not-allowed;
}
.trx-instructor {
  background-image: url("../../../assets/bicycle-steelblue.svg") !important;  //colocar el trx strelblue aqui
  background-size: contain;
  background-position: center;
  cursor: not-allowed;
}
.seat-admin-instructor {
  background-image: url("../../../assets/bicycle-steelblue.svg") !important;
  background-size: contain;
  background-position: center;
}
.trx-admin-instructor {
  background-image: url("../../../assets/bicycle-steelblue.svg") !important;   //colocar el trx steelblue aqui
  background-size: contain;
  background-position: center;
}
.seat-number {
  position: absolute;
  left: 20px;
  top: 7px;
  font-size: var(--small-font-size);
  color: white;
  background-color: var(--purple-color);
  width: 16px;
  height: 16px;
  display: flex;
  align-items:center;
  justify-content: center;
}
.seat-number-instructor {
  position: absolute;
  color: white;
  background-color: var(--purple-color);
  display: flex;
  align-items:center;
  justify-content: center;
  font-size: var(--small-font-size);
  left: -14px;
  top: 25px;
  height: 15px;
}
