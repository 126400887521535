@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";


.container-menu-vertical {
  min-width: var(--width-menu-vertical);
  background-color: white;
  height: auto;
  min-height: 80vh;
  overflow: hidden;

  // Si le paso la clase hidden es porque se oculta en desktop.
  &.hidden {
    display: none;
    @include from($reserveChangeDesktop ) {
      display: block;
    }
  }
  
  &:hover {
    overflow-y: auto;
  }
}

.container-menu-vertical--header {
  padding: 1.8rem 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid var(--gray-normal);
}


.menu-vertical-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.menu-vertical-data {
  margin-left: 1rem;
  p:not(:first-child) {
    margin-bottom: 0;
    line-height: 1.2rem;
  } 
}


.menu-vertical-data--name {
  font-size: 1rem;
  color: var(--blue-color);
  font-weight: 700;
  margin-top: .2rem;
  margin-bottom: 1px;
}

// title del drawer
.ant-menu-submenu-title span, .sub-rol-item span{
  font-size: .95rem !important;
  color: var(--blue-color) !important;
  font-weight: 900 !important;
  line-height: 45px;
}

.ant-menu-submenu-title, .sub-rol-item {
  border-bottom: 1px solid var(--gray-normal);
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  height: 45px !important;
  line-height: 45px;
}

// Dandole estilos a los iconos del menu
.ant-menu-submenu-title, .ant-menu-item {
  svg {
    font-size: 1.1rem;
    position: relative !important;
    top: 1px !important;
    font-weight: 900;
  }
  .ant-menu-submenu-arrow {
    color: var(--blue-color) !important;
  }
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item {
  padding: 0 0 0 3.4rem !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  border-bottom: 1px solid var(--gray-normal);
}
