@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.body-space-right {
	@include from(560px) {
		padding-right: 2rem;
		border-right: 1px solid var(--gray-normal);
	}
}
.body-space-left {
	@include from(560px) {
		padding-left: 2rem;
	}
}
.tenis-select-size {
	width: 200px;
	height: 45px;
  &.small {
    height: 30px;
  }
}
.tenis-select-size .ant-select-selector {
	border: 2px solid var(--turquoise-color) !important;
	width: 100%;
	height: 100% !important;
	display: flex;
	align-items: center;
	border-radius: 15px !important;
	color: var(--turquoise-color) !important;
	font-weight: 600;
  &.small {
    border:none !important;
  }
}
.tenis-min-select-size {
	min-width: 225px;
}
.step1-time-container {
	justify-content: space-between;
	width: 475px;
	@include to(1090px) {
	    flex-direction: column;
	    justify-content: center;
	    width: 315px;
	}
	@include to(560px) {
		align-items: center;
	}
}
.step-resp {
	@include to(560px) {
		display: flex;
		flex-direction: column;
		// align-items: center;
	}
}
.block-active-check {
	background-color: var(--turquoise-color);
  	border-radius: 15px;
  	padding: 1rem;
  	width: 230px;
  	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: 15px 0;
	.title-small-blue {
    	color: var(--gray-normal);
  	}
}
.block-active {
	background-color: var(--gray-normal);
  	border-radius: 15px;
  	padding: 1rem;
  	width: 230px;
  	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: 15px 0;
}
