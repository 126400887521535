.spinner {
  width: 100%;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
  &.small {
    min-height: 50vh;
  }
  &.small_2 {
    min-height: 50px;
    max-height: 50px;
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
  }
}
