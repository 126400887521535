@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.reserve-inner-footer {
  // background-color: blue;
  margin-top: 1rem;
  display: flex;
  flex-direction: column-reverse;
  @include from(m) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  a {
    // background-color: lime;
    flex: 1;
    display: block;
    text-decoration: underline;
    text-align: center;
    font-size: 1rem;
    margin-bottom: 1rem;
    padding-right: 10px;
    @include from(m) {
      text-align: left;
      margin-bottom: 0;
    }
  }

  .container-btn-edit, .container-btn {
    flex: 1;
  }

  .container-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      width: 100%;
      a{
        text-decoration: none;
      }
    }
    @include from(m) {
      justify-content: flex-end;
      button {
        width: auto;
      }
    }
  }

  .container-btn-edit {
    // background-color: red;
    text-align: center;
    button {
      margin-bottom: .5rem;
      width: 100%;
    }
    @include from(m) {
      display: flex;
      gap: .5rem;
      justify-content: flex-end;
      align-items: center;
      button {
        margin-bottom: 0;
        width: auto;
      }
    }
    @include from(960px) {
      gap: .9rem;
    }
  }
}


.reserve-back-button {
  margin-top: 1rem;
  @include from(m) {
    margin-top: 0;
  }
}
