@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";


.reserve-selection-box {
	margin: 0.3rem;
  padding: 0 1rem 1rem;
  background-color: white;
  border-radius: var(--border-radius-box);
  box-shadow: var(--box-shadow);
  text-align: center;
  max-width: 173px;
  min-width: 173px;
}

.reserve-selection-box--name {
  margin-bottom: .5rem;
  text-align: center;
  letter-spacing: -0.24px;
  color: var(--blue-color);
  font-weight: 600;
  font-size: var(--body-font-size);
}

.reserve-selection-box--img {
  width: 125px;
  height: 125px;
}
