@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.container-step-5 {
  .container-step-5-parte-1,  .container-step-5-parte-2{
    padding-top: 1rem;
  }

  .container-step-5-parte-2 {
    border-top: 1px solid var(--gray-normal);
  }

  border-top: 1px solid var(--gray-normal);
  @include from(990px) {
    display: flex;
    border-top: 1px solid var(--gray-normal);
    border-bottom: 1px solid var(--gray-normal);
    padding-top: 2rem;

    .container-step-5-parte-1 {
      padding-top: 0;
    }

    .container-step-5-parte-2 {
      padding-left: 2rem;
      border-top: none;
      padding-top: 0;
    }
  }
}
