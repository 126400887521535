@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.container-reserve-indoor-step-4 {
  @include from(990px) {
    display: flex;
  }
  .data {
    margin-bottom: 0;
  }
}

.reserve-indoor-step-4-parte-1 {
  @include from(990px) {
    width: 40%;
    padding-right: 1rem;
    border-right: 1px solid var(--gray-normal);
  }
}
.reserve-indoor-step-4-parte-2 {
  @include from(990px) {
    width: 60%;
    padding-left: 1rem;
  }
}

// Mapeo
.map-indoor-cycling{ 
  width: 100%;
  height: auto;
  // background-color: red !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.seat-content {
  width: 100% !important;
  padding-right: 1rem !important;
  // background-color: chartreuse !important;
}

.seat-picker {
  width: 100% !important;
  justify-content: space-between !important;
}

.seat-picker__row {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.7rem !important;
  // background-color: cyan;
}

.seat {
	background-color: transparent !important;
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;
	height: 30px;
	width: 30px;
  margin: 0;
}

.seat--enabled { //aqui poner bicicleta verde (disponible)
  background-image: url("../../../assets/bicycle-turquose.svg") !important; 
  background-size: contain;
  background-position: center;
}
.seat--reserved { //aqui poner bicicleta gris (no disponible)
  background-image: url("../../../assets/bicycle-gray.svg") !important;
  background-size: contain;
  background-position: center;
}
.seat--selected { //aqui poner bicicleta azul (seleccionada)
  background-image: url("../../../assets/bicycle-blue.svg") !important;
  background-size: contain;
  background-position: center;
  z-index: 5;
}

.seat__number {
	position: relative;
	left: 15px;
	color: white;
	background-color: var(--purple-color);
	width: 15px;
	height: 15px;
	display: flex;
	align-items:center;
	justify-content: center;
}

.reserve-indoor-status {
  width: 100%;
  display: flex;
  // background-color: red;
  justify-content: space-between; 
  flex-wrap: wrap;
  gap: .5rem;
  &--item {
    border-radius: 15px;
    flex: 1;
    padding: .3rem .5rem;
    font-size: var(--small-font-size);
    @include from(475px) {
      padding: .5rem;
      font-size: var(--bpdy-font-size);
    }
    box-sizing: border-box;
    color: var(--gray-normal);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    &.turquoise {
       background-color: var(--turquoise-color);
    }
    &.steelblue {
       background-color: var(--blue-color);
    }
    &.gray {
       background-color: var(--gray-normal);
       color: var(--steelblue-color);
    }
  }
}
