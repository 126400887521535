@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.body-step-3 {
  @include from(990px) {
    display: flex;
  }
  .data {
    margin-bottom: 0rem;
    line-height: 20px;
  }
}


.body-step-3-parte-1 {
  // background-color: steelblue;
  @include from(990px) {
    // min-width: 200px;
    padding-right: 2rem;
    border-right: 1px solid var(--gray-normal);
  }
  
}


.body-step-3-parte-2 {
  width: 100%;
  // background-color: pink;
  margin-top: 1rem;
  @include from(990px) {
  //   min-width: 314px;
  //   margin-top: 0;
  //   max-width: 432px;
    padding-left: 2rem;
  // }
  // @include from(1090px) {
  //   min-width: 450px;
  }
}


 // Container (Caja padre) de los 3 input 
.main-container-input-reserve {
  width: 100%;
  // @include from(990px) {
  //   width: 100%;
  //   display: flex;
  //   flex-wrap: wrap;
  //   justify-content: space-between;
  //   align-items: center;
  // }
}

 // Container de cada input 
.container-input-reserve {
  // background-color: chartreuse;
  --margin: .3rem;
  margin-top: 1rem;
  @include from(990px) {
    // width: calc(50% - var(--margin));
    // flex-basis: 180px;
    // flex-grow: 1;
    // max-width: calc(50% - var(--margin));
  }
  // Estilos a los input 
  input {
    width: 100%;
    border-radius: var(--radius-btn);
  }
}

 // Container de los 2 inputs radio
.main-container-input-radio {
  width: 100%;
  // background-color: green;
}

 // Container de cada input radio
.container-input-radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: .2rem
}
