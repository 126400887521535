@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.container-reserve-indoor-step-3 {
  // background-color: chartreuse;

  input[type='text'] {
    width: 100%;
    border-radius: var(--radius-btn);
  }

  @include from(990px) {
    display: flex;
    justify-content: space-between;
  }
  .data {
    margin-bottom: 0;
  }
}

.container-radio-indoor {
  width: 100%;
  display: flex;
  // justify-content: center;
  align-items: flex-end;
  gap: .5rem;
  text-align: left;
  // background-color: yellow;
  // flex-wrap: wrap;
  div {
    width: 100%;
    margin-bottom: 1rem;
  }
}


.container-reserve-indoor-parte-1 {
  flex: 50%;
  border-bottom: 1px solid var(--gray-normal);
  margin-bottom: 1rem;
  // background-color: violet;
  @include from(990px) {
    padding-right: 1rem;
    border-bottom: none;
    margin-bottom: 0;
  }
}
.container-reserve-indoor-parte-2 {
  width: 100%;
  text-align: right;
  flex: 50%;
  @include from(990px) {
    padding-left: 1rem;
    border-left: 1px solid var(--gray-normal);
  }
}

.container-reserve-indoor-parte-3 {
  // flex: 1;
  // border-top: 1px solid var(--gray-normal);
  // padding-top: 1rem;
  // @include from(990px) {
  //   padding-left: 1rem;
  //   border-top: none;
  //   padding-top: 0;
  //   border-left: 1px solid var(--gray-normal);
  // }
}
