@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.reserve-items-container {
  --columns: 1;
  gap: .25rem 1rem;
  @include from (375px) {
    --columns: 2;
  }
  @include from (800px) {
    --columns: 4;
  }
  @include from ($reserveChangeDesktop) {
    --columns: 2;
  }
  @include from (1065px) {
    --columns: 4;
  }
  display: grid;
  grid-template-columns: repeat(var(--columns), 173px);
  justify-content: center;
  justify-items: center;
}

// Modal para cuando estan bloquedas las reservas 

.bloqued-reserves {
  margin-top: 4rem;
  margin-bottom: 4rem;
  padding: 2rem;
  text-align: center;
  h6 {
    padding: 0 4rem;
  }
}
