@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.reserve-body {
	display: flex;
  max-width: 100%;
  // min-height: 100vh;
  margin-top: var(--reserveHeaderHeight);
  background-color:  var( --gray);
}

.reserve-body-main {
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
 
	position: relative;
  @include from($reserveChangeDesktop) {
    width: calc(100% - var(--width-menu-vertical));
     border-left: 1px solid var(--gray);
  }
  
  // Este padding de 6rem es para el que el contenido baje ya qu el titulo de cada Reserva esta abssoluto  
  // padding-top: 6rem;
	
}

.reserve-body-center-x {
	display: flex;
	justify-content: center;
}

.reserve-body-center-y {
	display: flex;
	align-items: center;
}
