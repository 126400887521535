.action-input-container {
	// width: 200px;
	display: flex;
  align-items: center;
  gap: .4rem;
  
  
  input {
    border: 1px solid var(--gray-semibold);
    &::placeholder {
      color: var(--gray-semibold);
      // font-weight: bold;
    }
  }
}
.action-search-field {
	flex: 1;
}
.action-search-button {
	width: auto;
	display: flex;
	justify-content: center;
	align-items: center;
}
